import * as React from "react"
import { Link } from "gatsby";
import '../styles/cgview.css';
import { fetchFeatures } from '../utils/FetchUtils';
import * as style from "../styles/editor.module.css";
import LinkIcon from '@mui/icons-material/Link';
import GlobalContext from "../context/optionContext";
import { MenuItem, Select, InputLabel, FormControl, FormControlLabel, Checkbox } from '@mui/material';

const CGV = require('cgview');
const allTargetSpecies = ["E. Coli (Escherichia coli str. K-12 substr. MG1655)", "Bakers Yeast (Saccharomyces cerevisiae S288C)", "Caenorhabditis elegans", "Fruit Fly (Drosophila melanogaster)", "Thale Cress (Arabidopsis thaliana)", "Human (Homo sapiens)", "Mouse (Mus musculus)", "Rat (Rattus norvegicus)", "Zebrafish (Danio rerio)", "African Clawed Frog (Xenopus laevis)"]

function CustomLinkIcon() {
  return (<LinkIcon sx={{ transform: "rotate(-30deg)", "vertical-align": "middle", "margin-left": "5px" }} />)
}

function MiniEditor(props) {
  const { theme } = React.useContext(GlobalContext);
  const [localData, setLocalData] = React.useState([]);

  const [cgvFormat, setCgvFormat] = React.useState("circular");
  const { sequence, nameSearch } = props;

  const [codonOptimization, setCodonOptimization] = React.useState(false)
  const [targetSpecies, setTargetSpecies] = React.useState(allTargetSpecies[0])

  const json = {
    "cgview": {
      "version": "1.1.0",
      "sequence": {
        seq: sequence
      },
      "features": localData,
      "legend": {
        // Maps the preset feature data from above into the legend
        "items": [],
        "visible": false
      },
      "tracks": [
        {
          "name": "Features",
          "position": 'both',
          "dataType": 'feature',
          "dataMethod": 'source',
          "dataKeys": 'json-feature'
        },

      ]
    }
  }

  React.useEffect(() => {
    // If it is currently getting fed a new plasmid
    fetchFeatures(sequence)
      .then(featureTemp => {
        setLocalData(featureTemp);
      }
      )
      .catch(err => console.log(err))
  }, [])

  React.useEffect(() => {
    const cgv = new CGV.Viewer('#my-viewer', {
      height: 300,
      width: 600,
    });

    cgv.io.loadJSON(json);
    const myNode = document.getElementById("my-viewer");

    cgv.annotation.update({
      "font": "monospace, plain, 10.5"
    })
    cgv.ruler.update({
      "font": "sans-serif, plain, 8"
    })
    cgv.backbone.update({ "color": "black" })
    cgv.ruler.update({ "color": "black" })
    myNode.removeChild(myNode.childNodes[0]);
    cgv.settings.update({ format: cgvFormat });

    cgv.draw()

  }, [localData, cgvFormat])


  return (
    <div class={style.sidebarholder} style={{ ...theme }}>
      <div id='my-viewer'><div></div></div>
      <div>{`Sequence:`}</div>
      <div class={style.sequence}>
        {sequence}
      </div>
      {/* <FormControlLabel
                    control={<Checkbox defaultChecked={false} />}
                    label="Optional: Apply Codon Optimization"
                    onChange={() => { setCodonOptimization(!codonOptimization) }} />
                  {codonOptimization && <FormControl required fullWidth style={{ marginTop: '10px', marginBottom: '15px' }}>
                    <InputLabel id="select-target-species-for-codon-opt">Target Species</InputLabel>
                    <Select
                      labelId="select-target-species-for-codon-opt"
                      id="target-sp-label-simple"
                      value={targetSpecies}
                      label="Target Species"
                      onChange={(e) => {
                        console.log(e.target.value)
                        setTargetSpecies(e.target.value)
                      }}
                    >
                      {allTargetSpecies.map((v, i) => {
                        return (
                          <MenuItem
                            value={`${v}`} key={v}>
                            {v}
                          </MenuItem>
                        )
                      })}
                      {/* <MenuItem value="paste-seq">Paste nucleotide sequence</MenuItem>
                                  <MenuItem value="upload-seq">Upload sequence file</MenuItem> */}
      {/* </Select> */}
      {/* </FormControl>} */}
      <Link to={`/`} state={{ nameSearch: nameSearch, codonOptimization: codonOptimization, targetSpecies: targetSpecies }} class={style.openlink}>Open in PlasMapper Editor<CustomLinkIcon /></Link>
    </div>
  )
}

export default MiniEditor
